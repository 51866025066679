.reveal {
    @include reveal-modal-width(95%, rem-calc(1336));
    @include content-box-shadow;
    padding: 0;

    .reveal-wrapper {
        @include content-border;

        padding: rem-calc(60 50);

        @include breakpoint(medium down) {
            padding: rem-calc(30 25);
        }
    }

    .close-button {
        top: rem-calc(40);
        right: rem-calc(39);

        &:before {
            content: '';
            display: block;
            width: rem-calc(24);
            height: rem-calc(24);
            border: 1px solid $blue;
            border-radius: 50%;
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        span {
            text-align: center;
            font-size: rem-calc(28);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-weight: $font-weight-light;
            color: $blue;
        }
    }
}