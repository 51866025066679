.content-box-contact-form {
    &--centered {
        margin: 0 auto !important;
    }
    .content-box-content {
        @include xy-grid;

        .content-box-title {
            @include xy-cell(12);

            @include breakpoint(medium down) {
                @include xy-cell($gutters: 0);
            }
            &--small {
                font-size: 20px;
                padding-bottom:.4rem;
            }
        }
       

        .form {
            @include xy-cell(6);

            @include breakpoint(medium down) {
                @include xy-cell($gutters: 0);
            }

            .form-row {
                @include xy-grid;

                .form-cell {
                    @include xy-cell($size: auto);

                    @include breakpoint(medium down) {
                        @include xy-cell($gutters: 0);
                    }

                    &--checkbox {
                        display: table;
                    }

                    input[type="checkbox"] {
                        margin-right: rem-calc(11);
                    }

                    .label-checkbox {
                        display: table-cell;
                    }
                }
            }
        }

       

            .form-photo-container {
                @include xy-cell(6);

                @include breakpoint(medium down) {
                    @include xy-cell($gutters: 0);
                    margin-top: rem-calc(40);
                }

                .form-photo {
                    @include image-content-border;

                    img {
                        width: 100%;
                    }
                }
            }
        }
        .content-box-content--full {
            .form {
                @include xy-cell(100%);

            }
        }
    }

    .form-field-custom-interest {
        display: none;
    }

    .form-confirmation-message {
        @include reveal-modal-base;
        @include reveal-modal-width;
    }

    .form-confirmation-message--close-button {
        position: absolute;
        top: rem-calc(-10);
        right: rem-calc(-10);
    }

    .form-confirmation-message--content {
        text-align: center;
    }

    .form-confirmation-message--controls {
        text-align: center;
    }
    
    
    
    .contact-page-form {
        display:none;
       &--visble {
           display: block;
       } 
    }