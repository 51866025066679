.header {
    @include xy-grid;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: $z-index-header;

    .logo {
        @include xy-cell($size: shrink, $gutters: rem-calc(0));
        @include flex-align($y: bottom);
        @include flex;
        z-index: 1;


       
       img {
           height:80.8px;
       }
       @include breakpoint(medium down) {
           margin: 0;
           @include xy-cell($size: auto, $gutters: rem-calc(0));
           img {
          height:67px;
               
           }
       }
    }

    .main-navigation-mobile-button {
        @include xy-cell($size: shrink, $gutters: 0);
        @include flex;
        cursor: pointer;
        position: relative;
        width: rem-calc(79);
        height: rem-calc(67);
        background: $blue;
        display: none;
        z-index: 1;

        .border {
            position: absolute;
            top: 3px;
            right: 3px;
            bottom: 3px;
            left: 3px;
            border: 1px solid $white;
        }

        .line {
            width: rem-calc(34);
            height: rem-calc(2);
            background-color: $white;
            display: block;
            position: absolute;
            left: rem-calc(23);
            margin: rem-calc(4) auto;
            transition: transform 150ms ease-in-out 0ms,
                        top 300ms ease-in-out 50ms,
                        opacity 150ms ease-in-out 150ms;
            // transform-origin: 50% 50%;

            &--1 {
                top: 15px;
            }
            &--2 {
                top: 21px;
            }
            &--3 {
                top: 27px;
            }
        }
        .label {
            color: $white;
            text-transform: uppercase;
            font-size: rem-calc(12);
            display: block;
            margin-top: rem-calc(26);
            
            &--menu {}
            &--close {
                display: none;
            }
        }

        @include breakpoint(medium down) {
            display: block;
        }

        &.expanded {
            .line {
                transition: top 150ms ease-in-out 0ms,
                transform 300ms ease-in-out 50ms,
                opacity 300ms ease-in-out 0ms;
                
            }
            .line--1 {
                transform: rotate(45deg);
                top: rem-calc(21);
            }
            .line--2 {
                opacity: 0;
            }
            .line--3 {
                transform: rotate(-45deg);
                top: rem-calc(21);
            }
            .label--menu {
                display: none;
            }
            .label--close {
                display: block;
            }
        }
    }

    .main-navigation {
        @include xy-cell(auto, false);
        @include flex-align($x: right, $y: middle);
        @include flex;
        @include navigation-content-border;
        background: $blue;

        @include breakpoint(medium down) {
            display: none;
        }
        &:before {
            left:0px;
        }

        .main-navigation-list {
            list-style: none;
            margin: rem-calc(0 36 0 0);
            
            @include breakpoint(xlarge down) {
                margin-right: 2.5vw;
            }

            .main-navigation-list-item {
                display: inline-block;
                margin-left: rem-calc(41);

                @include breakpoint(xlarge down) {
                    margin-left: 2.43vw;
                }

                &:first-child {
                    margin-left: none;
                }

                &.is-active {
                    a {
                        &:after {
                            opacity: 1;
                        }
                    }
                }

                a {
                    display: block;
                    color: rgba($white, 0.75);
                    font-weight: $font-weight-regular;
                    font-size: rem-calc(18);
                    padding: rem-calc(19 6 17);
                    position: relative;

                    @include breakpoint(medium down) {
                        font-weight: $font-weight-light;
                        font-size: rem-calc(30);
                        padding: rem-calc(11 6 8);
                    }

                    &:hover {
                        color: $white;

                        &:after {
                            opacity: 1;
                        }
                    }

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: -4px;
                        left: 0;
                        right: 0;
                        border-bottom: 3px solid $white;
                        opacity: 0;
                        transition: opacity 0.25s ease;
                    }
                }
            }
        }
    }

    .main-navigation-mobile {
        display: none;

        @include breakpoint(medium down) {
            display: block;
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            bottom: 100%;
            background: $white;
            transition: bottom 250ms ease;
            overflow: hidden;
        }

        &.expanded {
            @include breakpoint(medium down) {
                bottom: 0;
            }
        }

        .main-navigation-mobile-list {
            margin: rem-calc(80 0 0);
            list-style: none;
            width: 100%;

            .main-navigation-mobile-list-item {
                background: $blue;
                display: block;
                text-align: center;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    pointer-events: none;
                    top: 3px;
                    right: 3px;
                    bottom: 3px;
                    left: 3px;
                    border: 1px solid $white;
                }

                a {
                    display: block;
                    color: rgba($white, 0.75);
                    font-weight: $font-weight-light;
                    font-size: rem-calc(30);
                    padding: rem-calc(11 6 8);
                    position: relative;

                    &:hover {
                        color: $white;
                    }
                }

                .main-navigation-mobile-sub-list {
                    list-style: none;
                    background: $white;
                    position: relative;
                    margin: 0;
                    padding: rem-calc(16 24);

                    &:before {
                        content: '';
                        position: absolute;
                        pointer-events: none;
                        top: 13px;
                        right: 13px;
                        bottom: 13px;
                        left: 13px;
                        border: 1px solid rgba(210,210,217,0.50);
                    }

                    .main-navigation-mobile-sub-list-item {
                        text-align: left;

                        a {
                            color: $blue;
                            font-size: rem-calc(22);

                            .icon-svg {
                                width: rem-calc(44);
                                height: rem-calc(44);
                                vertical-align: middle;
                                margin-right: rem-calc(9);

                                .icon-svg-fill {
                                    fill: $medium-gray;
                                }
                            }

                            span {
                                vertical-align: middle;
                            }
                        }
                    }
                }
            }
        }
    }
}