.content-box-core-values-list-container {
    @include orbit-wrapper;

    background: url('/_assets/img/core-values-list-bg.jpg') no-repeat;
    background-size: contain;
    background-position: bottom;
    padding-bottom: 53.819444%;
    width: 100%;
    position: relative;
    margin-top: rem-calc(70);

    @include breakpoint(medium down) {
        margin-top: 0;
    }

    .content-box-core-values-list-title-container {
        position: absolute;
        text-align: center;
        width: 100%;
        top: rem-calc(37);

        @include breakpoint(medium down) {
            display: none;
        }

        .content-box-core-values-list-control {
            vertical-align: middle;
            margin-top: rem-calc(4);

            &--previous {}
            &--next {}

            .icon-svg {
                width: rem-calc(27);
                height: rem-calc(27);

                .icon-svg-fill {
                    fill: $medium-gray;
                }
            }
        }
        .content-box-core-values-list-title {
            font-size: rem-calc(18);
            font-weight: $font-weight-medium;
            display: inline-block;
            vertical-align: middle;
            margin: rem-calc(0 32);
        }
    }

    .content-box-core-values-list {
        @include orbit-container;
        position: absolute;
        max-width: rem-calc(1220);
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        top: rem-calc(82);

        @include breakpoint(medium down) {
            position: relative;
            top: auto;
        }

        .content-box-core-values-list-item {
            @include orbit-slide;
            text-align: center;

            &.is-active {}

            .icon-svg {
                width: rem-calc(62);
                height: rem-calc(45);
                position: absolute;

                @include breakpoint(medium down) {
                    width: rem-calc(42);
                    height: rem-calc(30);
                }

                &:first-child {
                    top: 0;
                    left: 0;
                }
                &:last-child {
                    bottom: 0;
                    right: 0;
                }

                .icon-svg-fill {}
            }

            .content-box-core-values-list-item-text {
                font-family: $font-family-aktiv-grotesk-thin;
                font-weight: $font-weight-thin;
                font-size: rem-calc(53);
                line-height: 1.2;
                padding: rem-calc(0 70);
                display: inline-block;

                @include breakpoint(medium down) {
                    font-size: rem-calc(27);
                    padding: 0;
                    padding: rem-calc(30 42);
                }
            }
        }
    }
}