.image-careers-title {
    margin-bottom: rem-calc(5);
}
.careers-navigation-list {
    @include xy-grid;
    
    .careers-navigation-list-item {
        
        display: block;
        @include xy-grid;
        @include xy-cell(100%);
        @include breakpoint(xlarge){
            
        @include xy-cell(50%);
        }
        margin-top: rem-calc(20);

        @include breakpoint(small down) {
            @include xy-grid($direction: vertical);
        }

        .careers-navigation-list-item-content {
            @include xy-cell($size: auto, $gutters: 0);
            background: $light-gray;
            text-align: left;
            padding: rem-calc(19 34 15);

            @include breakpoint(small down) {
                padding: rem-calc(9 15 7);
            }

            .careers-navigation-list-item-position {
                display: block;
                font-size: rem-calc(21);
                font-weight: $font-weight-light;
                color: $dark-gray;
                border-bottom: 1px solid #D2D2D9;
                margin-bottom: rem-calc(2);
            }
            .careers-navigation-list-item-location {
                display: block;
                font-size: rem-calc(14);
                font-weight: $font-weight-light;
            }
        }
        .careers-navigation-list-item-button {
            @include xy-cell($size: shrink, $gutters: 0);
            @include details-button-border;
            @include flex;
            @include flex-align(center, middle);

            background: $blue;
            color: $white;
            font-size: rem-calc(18);
            font-weight: $font-weight-regular;
            padding-left: rem-calc(33);
            padding-right: rem-calc(44);
            position: relative;

            @include breakpoint(small down) {
                padding: rem-calc(8 24 10);
                @include flex-align(right, middle);
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                right: rem-calc(15);
                margin-top: rem-calc(1);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: rem-calc(7 0 7 5);
                border-color: transparent transparent transparent $white;
            }
        }
    }
}

.careers-cards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    @include breakpoint(xlarge up){
    align-items: flex-start;
    }
    justify-content: center;
    &__card {
        @include breakpoint(medium up){
        @include xy-cell(6, $gutters:  2rem)
        }
        @include breakpoint(large up){
        @include xy-cell(4, $gutters:  2rem)
        }
        @include breakpoint(xlarge up){
        @include xy-cell(3, $gutters:  2rem)
        }
        margin-bottom: 2rem;
    @include content-border;
    @include content-box-shadow;
    background: $white;
    text-align: center;
    }
    &__image {
        margin-bottom: 1rem;
    }
    &__headline {
        font-size: 1.31rem;
    }
    &__copy {
        
    }
}