.homepage-intro-content {
    @include xy-cell($size: shrink, $gutters: rem-calc(100), $gutter-position: right);

    @include breakpoint(medium down) {
        @include xy-cell;
    }

    @include breakpoint(small down) {
        @include xy-cell($gutters: 0);
    }

    .content-box {
        text-align: center;
        margin-top: rem-calc(53);

        .image-top-offset {
            margin: rem-calc(-101) auto rem-calc(20);
            position: relative;
        }

        .content-box-content {
            padding: rem-calc(0 10);

            @include breakpoint(medium down) {
                padding: 0;
            }

            .content-box-title {
                font-size: rem-calc(23);

                @include breakpoint(medium down) {
                    font-size: rem-calc(21);
                }
            }

            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.news-slider {
    display:flex;
        @include breakpoint(medium down){
            flex-wrap:wrap;
            margin-bottom:10rem;
        }
    width:100%;
    &__control {
        width:5%;
        display:flex;
        justify-content:center;
        align-items:center;
        @include breakpoint(medium down){
            order:1;
            width:50%;
        }
    }
    &__button {
        width:30px;
        flex-shrink:0;
        cursor:pointer;
    }
    &__slides {
        width:90%;
        @include breakpoint(medium down){
            width:100%;
            margin-bottom:1rem;
        }
        flex-shrink:1;
    }
    .content-row {
        margin:0 !important;
    }
   
    
}

.slick-list {
    @include breakpoint(large){
        
    padding: 4rem 0 !important;
    }
}
.slick-track {
    display: flex !important;

    .slick-slide {
        display: flex !important;
        height: auto !important;
        align-items: center !important;
        justify-content: center !important;
        > div {
            height: 100%;
            display:flex;
            align-items:center;
        }
    }
}