* {
    @include disable-mouse-outline;
}

a {
    transition: all 0.25s ease;
}
.anchor-link {
    display: block;
    position: relative;
    top: rem-calc(-100);
    visibility: hidden;
}

body {
    @include breakpoint(medium down) {
        margin-top: rem-calc(58);
    }
}

.videos {
    display:grid !important;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
.video {
    position:relative;
    grid-column: span 2 / span 2;
   background:white;
   padding:2rem;
   margin:1rem;
   @include breakpoint(large){
   margin:2rem;
}
   
}
.video:before {
    content: '';
    position: absolute;
    pointer-events: none;
    border:1px solid;
    border-color: rgba(210,210,217,.5);
    top: 12px;
    left: 12px;
    right: 12px;
    bottom: 12px;
}
.video iframe {
    width:100%;
    aspect-ratio:16/9;
   height:auto;
}

.video--half {
    grid-column: span 2 / span 2;
    @include breakpoint(large ) {
    grid-column: span 1 / span 1;
    }
}