.perch-debug {
    @include breakpoint(medium down) {
        display: none;
    }
}

.main-content {
    overflow: hidden;
}

.content-row {
    margin-bottom: rem-calc(55);

    &.content-row-core-values-list {
        margin-bottom: 0;
    }
}

.content-row-container {
    @include xy-grid;

    @include breakpoint(medium down) {
        @include xy-grid-container;
    }
}

.content-row-container--contained {
    &:not(.content-row-container--landing-page) {
        @include xy-grid-container;
    }

    @include xy-grid;

    .content-box {
        @include xy-cell;

    }
}

.content-row-content-box--with-photo-50-split {
    .content-box-photo {
        @include xy-cell($size: 6, $gutters: 0);

        @include breakpoint(medium down) {
            @include xy-cell($gutters: 0);
        }

        .content-box-photo-list-item {
            @include image-content-border-extend-right;
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;

            @include breakpoint(medium down) {
                background: none !important;

                &:before {
                    border-bottom: none;
                    bottom: 0;
                    right: 12px;
                    border-right: 1px solid $white;
                }
            }

            img {
                display: none;

                @include breakpoint(medium down) {
                    display: block;
                }
            }
        }
    }

    .content-box {
        @include xy-cell($size: 6, $gutters: 0);
        @include content-border;

        &:before {
            @include breakpoint(large up) {
                left: 0;
                border-left: none;
            }
        }

        @include breakpoint(medium down) {
            @include xy-cell($gutters: 0);

            &:before {
                top: 0;
                border-top: none;
            }
        }
    }
}

.content-box {
    @include xy-cell($gutters: 0);
    @include xy-grid;
    @include content-border;
    @include content-box-shadow;
    background: $white;
    position: relative;

    @include breakpoint(medium down) {
        padding: rem-calc(24 24);
    }

    .content-box-content {
        @include xy-cell;

    }

    .content-box-title {
        font-family: $font-family-aktiv-grotesk-thin;
        font-weight: $font-weight-thin;
        font-size: rem-calc(26);
        line-height: rem-calc(27);
        color: $blue;
        border-bottom: 1px solid #D2D2D9;
        padding-bottom: rem-calc(12);
        margin-bottom: rem-calc(12);
    }
}

.content-box-careers-cards {
    background: transparent;
    box-shadow: none;
    text-align: center;
    margin: 0 auto;
    @include breakpoint(large up) {
    width: 60%;
    }
    @include breakpoint(xxlarge up) {
    width: 50%;
    }


}

.content-box-careers-cards-title {
      font-family: $font-family-aktiv-grotesk-thin;
        font-weight: $font-weight-thin;
        font-size: rem-calc(26);
        line-height: rem-calc(27);
        color: $blue;
        border-bottom: 1px solid #D2D2D9;
        padding-bottom: rem-calc(12);
        margin-bottom: rem-calc(12);
        text-align: center;
    width: 90%;
           @include breakpoint(large up) {
    width: 60%;
    }
    @include breakpoint(xxlarge up) {
    width: 50%;
    }
    margin: 0 auto;
        margin-bottom: 2rem;
    border: none;
}

.content-box-careers-cards::before {
    display: none;
}

.content-box-services-navigation-box {
    @include flex-align($x: center);
}


.content-box-side-photo {
    &.content-box-side-photo--half {
        @include xy-cell(100%);
        @include breakpoint(medium){
        @include xy-cell(1/2);

        }
        padding: 2.5rem 1.5rem;

        .content-box-photo {
            @include xy-cell($size: 100%, $gutters: rem-calc(30));
            margin-bottom: 1rem;
            @include breakpoint(xlarge) {
                margin-bottom: 0;
            @include xy-cell($size: 40%, $gutters: rem-calc(30));
        }

        }

        .content-box-content {
            @include xy-cell($size: 100%, $gutters: rem-calc(30));
            @include breakpoint(xlarge) {

            @include xy-cell($size: 60%, $gutters: rem-calc(30));
            }

           

            p:last-of-type {
                margin: 0;
            }
        }

    }

    .content-box-photo {
        @include xy-cell($size: 41.6%, $gutters: rem-calc(42));

        @include breakpoint(medium down) {
            @include xy-cell($gutters: 0);

            @include -zf-breakpoint-value(auto, $grid-margin-gutters) {
                margin-bottom: rem-calc($-zf-bp-value);
            }
        }
    }

    .content-box-content {
        @include xy-cell($size: 58.4%, $gutters: rem-calc(42));
        &--full {
            @include xy-cell($size: 100%);
            
            margin-bottom: 1rem;
        }

        &--half {
            @include xy-cell($size: 100%, $gutters: rem-calc(30));
            @include breakpoint(medium) {
            @include xy-cell($size: 50%, $gutters: rem-calc(30));
            }
        }

        @include breakpoint(medium down) {
            @include xy-cell($gutters: 0);
        }
    }
}

.content-box-side-illustration {
    &.content-box-side-illustration--right {
        .content-box-content {
            @include flex-order(1);
        }

        .content-box-illustration {
            @include flex-order(2);
        }

        @include breakpoint(medium down) {
            .content-box-content {
                @include flex-order(2);
            }

            .content-box-illustration {
                @include flex-order(1);
            }
        }
    }

    .content-box-illustration {
        @include xy-cell($size: 41.6%, $gutters: rem-calc(42));

        @include breakpoint(medium down) {
            @include xy-cell($size: 100%);
            margin-bottom: rem-calc(20);
        }

        .content-box-illustration-image {
            position: relative;

            img,iframe {
                width: 100%;
            }
        }

        .content-box-illustration-caption {
            background: $light-gray;
            color: $blue;
            font-family: $font-family-aktiv-grotesk-thin;
            font-weight: $font-weight-thin;
            font-size: rem-calc(39);
            line-height: rem-calc(40);
            text-align: center;
            margin-right: rem-calc(-38);
            margin-top: rem-calc(35);
            padding: rem-calc(16 20 25);
        }
    }

    .content-box-content {
        @include xy-cell($size: 58.4%, $gutters: rem-calc(42));

        @include breakpoint(medium down) {
            @include xy-cell($size: 100%, $gutters: 0);
        }
    }
}

.content-box-careers {
    .content-box-content {
        @include xy-cell($size: 50%, $gutters: rem-calc(30));

        @include breakpoint(medium down) {
            @include xy-cell;
            
            
        }
        
        &--full {
            @include xy-cell($size: 100%);
            
        }
        

        .content-box-photo-list {}

        .content-box-photo-list-item {
            position: relative;
            @include image-content-border;

            img {
                width: 100%;
            }
        }
    }
    .content-box-photo{
        @include xy-cell($size: 50%, $gutters: rem-calc(30));
    
        @include breakpoint(large up) {
            &:before {
                display: none;
            }
        }
    
        @include breakpoint(medium down) {
            @include xy-cell($gutters: 0);
            @include flex-order(1);
    
            @include content-border;
            @include content-box-shadow;
            position: relative;
            background: $white;
    
            margin-bottom: rem-calc(40);
        }
    }

    .content-box-careers-content {
        @include xy-cell($size: 50%, $gutters: rem-calc(30));
        text-align: center;

        @include breakpoint(medium down) {
            @include xy-cell;
            margin-top: rem-calc(20);
        }
    }
}

.content-box-contact {
    @include breakpoint(medium down) {
        padding: 0;
        background: none;

        &:before {
            display: none;
        }

        box-shadow: none;
    }

    .content-box-content {
        @include xy-cell($size: 50%, $gutters: rem-calc(30));

        @include breakpoint(large up) {
            &:before {
                display: none;
            }
        }

        @include breakpoint(medium down) {
            @include xy-cell($gutters: 0);
            @include flex-order(1);

            @include content-border;
            @include content-box-shadow;
            position: relative;
            background: $white;

            margin-bottom: rem-calc(40);
        }
    }
    .content-box-photo{
        @include xy-cell($size: 50%, $gutters: rem-calc(30));
    
        @include breakpoint(large up) {
            &:before {
                display: none;
            }
        }
    
        @include breakpoint(medium down) {
            @include xy-cell($gutters: 0);
            @include flex-order(1);
    
            @include content-border;
            @include content-box-shadow;
            position: relative;
            background: $white;
    
            margin-bottom: rem-calc(40);
        }
    }
    

    .content-box-contact-content {
        @include xy-cell($size: 50%, $gutters: rem-calc(30));
        @include flex-order(1);

        @include breakpoint(medium down) {
            @include xy-cell($gutters: 0);
            @include flex-order(2);
        }
    }
}

.content-box-photo-list-container {
    @include orbit-wrapper;

    .content-box-photo-list {
        @include orbit-container;

        .content-box-photo-list-item {
            @include orbit-slide;

            .content-box-photo-list-item-image {
                @include orbit-image;
            }
        }
    }

    .content-box-photo-list-controls {
        position: absolute;
        right: 0;
        bottom: 0;
        background: $white;
        padding: rem-calc(16 10 10 18);

        @include breakpoint(small down) {
            padding: rem-calc(8 5 0 9);
        }

        .content-box-photo-list-control {
            cursor: pointer;

            &--previous {
                margin-right: rem-calc(18);
            }

            .icon-svg {
                width: rem-calc(34);
                height: rem-calc(34);

                .icon-svg-fill {
                    fill: $blue;
                }
            }
        }
    }
}

.content-box-photo-list-item {
    position: relative;
    @include image-content-border;

    img {
        width: 100%;
    }
}

.stats-list {
    @include xy-grid;
    width: 100%;
    position: relative;

    .stats-list-item {
        @include xy-cell($size: auto);
        @include xy-grid($direction: vertical);
        @include content-box-shadow;
        background: $medium-gray;
        text-align: center;

        @include breakpoint(medium down) {
            @include -zf-breakpoint-value(auto, $grid-margin-gutters) {
                margin-bottom: rem-calc($-zf-bp-value);
            }
        }

        .stats-list-item-title {
            @include xy-cell($size: shrink, $gutters: 0);
            font-size: rem-calc(20);
            line-height: 1;
            color: $blue;
            font-weight: $font-weight-regular;
            padding-top: rem-calc(20);

            @include breakpoint(medium down) {
                font-size: rem-calc(16);
            }

        }

        .stats-list-item-stat {
            @include xy-cell($size: shrink, $gutters: 0);
            font-size: rem-calc(70);
            line-height: 1;
            color: $white;
            text-shadow: 0 2px 0 rgba(10, 35, 102, 0.32);
            font-weight: $font-weight-medium;
            padding-bottom: rem-calc(21);

            @include breakpoint(medium down) {
                font-size: rem-calc(50);
            }

        }

        .stats-list-item-description {
            @include xy-cell($size: auto, $gutters: 0);
            @include stat-box-content-border;
            background: $white;
            font-size: rem-calc(21);
            line-height: rem-calc(24);
            font-weight: $font-weight-light;

            @include breakpoint(medium down) {
                font-size: rem-calc(16);
            }
        }
    }
}

.faqs-list {
    @include accordion-container;
    position: relative;
    width: 100%;
    margin-bottom: 0;

    .faqs-list-item {
        @include accordion-item;
        @include faqs-list-item-border;
        @include content-box-shadow;
        background: $white;
        margin-bottom: rem-calc(8);

        &.is-active {
            .faqs-list-item-title {
                &:before {
                    content: '\2013';
                }

                &:after {
                    content: '';
                    display: block;
                    width: 100%;
                    border-bottom: 1px solid rgba(210,210,217,0.50);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }
        }

        .faqs-list-item-title {
            @include accordion-title;

            &:hover {
                background-color: $medium-gray;
            }

            &:before {
                margin-top: 0;
                transform: translateY(-50%);
            }
        }
        
        .faqs-list-item-content {
            @include accordion-content;
        }
    }
}

ul {
    &.columns-2 {
        column-count: 2;

        @include breakpoint(medium down) {
            column-count: 1;
        }
    }

    &.columns-2--large {
        font-size: rem-calc(24);
        line-height: 1.25;

        @include breakpoint(small down) {
            font-size: rem-calc(20);
            margin-left: rem-calc(23);
        }

        li {
            margin-bottom: rem-calc(24);
        }
    }
}

.link-to-top {
    display: inline-block;
    position: absolute;
    right: rem-calc(28);
    bottom: rem-calc(25);

    .icon-svg {
        width: rem-calc(34);
        height: rem-calc(34);

        .icon-svg-fill {
            fill: $medium-gray;
        }
    }
}

.content-box-columns {
    @include xy-grid;

    .content-box-column {
        @include xy-cell($size: auto);

        @include breakpoint(medium down) {
            @include xy-cell;
        }
    }

    .content-box-column {
        @include xy-cell($size: auto);

        @include breakpoint(medium down) {
            @include xy-cell;
        }
    }
}

.image-we-are-mb {}

.image-we-are-mb--team-intro {
    // width: rem-calc(412);
}

.content-row-faqs {
    h2 {
        color: $blue;
    }
}