.content-row-services-navigation {
    @include xy-grid-container;
    @include xy-grid;

    @include breakpoint(medium down) {
        &:not(.content-row-services-navigation--landing-page){
        display: none;
        }
    }

    .content-box-services-navigation-box {
        @include xy-cell(12);
        @include xy-grid;
        background-color: $white;
        @include content-box-shadow;
        @include content-border;
        padding: rem-calc(28 38 32);

        .content-box-services-navigation-item {
            @include xy-cell(shrink);
            @include flex;
            @include flex-align(center, middle);
            padding-left: rem-calc(26);

            &:first-child {
                padding-left: 0;
            }

            a {
                font-size: rem-calc(26);
                font-family: $font-family-aktiv-grotesk-thin;
                font-weight: $font-weight-thin;

                .icon-svg {
                    vertical-align: text-top;
                    width: rem-calc(49);
                    height: rem-calc(49);
                    margin-right: rem-calc(4);

                    .icon-svg-fill {
                        fill: $medium-gray;
                    }
                }

                span {
                    display: inline-block;
                    margin-top: rem-calc(6);
                    vertical-align: text-top;
                }
            }
        }
    }
}

.content-row-services-list {
    @include breakpoint(large down) {
        padding: rem-calc(0 20);
    }
}

.content-row-container-services-list {
    &.content-row-container--landing-page {
        margin-top: 0;
        @include breakpoint(medium){
        padding: 0 2rem;
        }
        padding-top: 1rem;
    }

    margin-top: rem-calc(50);

    .services-list-item {
        @include xy-grid;
        @include xy-gutters($gutters: rem-calc(40), $negative: true);
        margin-top: rem-calc(20);

        &:first-child {
            margin-top: 0;
        }

        .services-list-item-photo {
            &.services-list-item-photo--landing-page {
                @include breakpoint(medium down){
                @include xy-cell(100%, true);
                margin-bottom: 1rem;

                }
                @include xy-cell(40%, true);

               


            }

            @include xy-cell($size: 54.25%, $gutters: rem-calc(60));

            @include breakpoint(large down) {
                @include xy-cell($size: 40%, $gutters: rem-calc(20));
            }

            @include breakpoint(medium down) {
                @include xy-cell;
            }

            .content-box-photo {
                position: relative;

                .overlay-container {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;

                    .overlay {
                        position: absolute;
                        width: 0;
                        height: 0;
                        z-index: 2;
                        background: $light-gray;

                        &.overlay_tl {
                            top: 0;
                            left: 0;
                            transition: all 750ms ease 750ms;
                            width: rem-calc(59);

                            @include breakpoint(small down) {
                                width: rem-calc(30);
                            }

                            &.overlay_odd {
                                width: rem-calc(118);

                                @include breakpoint(small down) {
                                    width: rem-calc(59);
                                }
                            }

                            &.overlay_1 {
                                width: rem-calc(140);

                                @include breakpoint(small down) {
                                    width: rem-calc(70);
                                }
                            }
                        }

                        &.overlay_tr {
                            top: 0;
                            right: 0;
                            transition: all 750ms ease 1000ms;
                            height: rem-calc(50);

                            @include breakpoint(small down) {
                                height: rem-calc(25);
                            }

                            &.overlay_odd {
                                height: rem-calc(164);

                                @include breakpoint(small down) {
                                    height: rem-calc(82);
                                }
                            }

                            &.overlay_1 {
                                height: 0;
                            }
                        }

                        &.overlay_bl {
                            bottom: 0;
                            left: 0;
                            transition: all 750ms ease 1250ms;
                        }

                        &.overlay_br {
                            bottom: 0;
                            right: 0;
                            transition: all 750ms ease 1500ms;
                            width: rem-calc(112);

                            @include breakpoint(small down) {
                                height: rem-calc(56);
                            }
                        }

                        &.overlay_full_1 {
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            width: auto;
                            height: auto;
                            transition: all 1250ms ease 250ms;
                        }

                        &.overlay_full_2 {
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            width: auto;
                            height: auto;
                            transition: all 1500ms ease 250ms;
                        }
                    }

                    &.aos-animate {
                        .overlay {
                            &.overlay_tl {
                                height: rem-calc(281);

                                @include breakpoint(small down) {
                                    height: rem-calc(140);
                                }

                                &.overlay_odd {
                                    height: rem-calc(98);

                                    @include breakpoint(small down) {
                                        height: rem-calc(49);
                                    }
                                }

                                &.overlay_1 {
                                    height: rem-calc(155);

                                    @include breakpoint(small down) {
                                        height: rem-calc(78);
                                    }
                                }
                            }

                            &.overlay_tr {
                                width: rem-calc(122);

                                @include breakpoint(small down) {
                                    width: rem-calc(61);
                                }

                                &.overlay_odd {
                                    width: rem-calc(38);

                                    @include breakpoint(small down) {
                                        width: rem-calc(19);
                                    }
                                }

                                &.overlay_1 {
                                    width: 0;
                                }
                            }

                            &.overlay_bl {}

                            &.overlay_br {
                                height: rem-calc(67);

                                @include breakpoint(small down) {
                                    height: rem-calc(45);
                                }
                            }

                            &.overlay_full_1 {
                                left: 100%;
                                bottom: 100%;
                            }

                            &.overlay_full_2 {
                                right: 100%;
                                top: 100%;
                            }
                        }
                    }
                }

                /* Photo Gallery */
                .content-box-photo-list-container {
                    .content-box-photo-list-controls {
                        background: transparent;
                        z-index: 2;
                        opacity: 0;
                        transition: opacity 750ms ease 1750ms;

                        &.aos-animate {
                            opacity: 1;
                        }
                    }

                    .content-box-photo-list {
                        .content-box-photo-list-item {}
                    }
                }

                /* Single Image & Photo Gallery */
                .content-box-photo-list-item {
                    &:before {
                        display: none;
                    }
                }
            }
            .content-box-photo {
                @include image-content-border;
                &:before {
                    z-index: 2;
                }
            }
        }

        .sevices-list-item-content {
            &.services-list-item-content--landingpage {
                &::before {
                    border: none;
                }

                box-shadow: none;
                @include xy-cell(60%, true);
                padding: 0;
                .icon-svg {
                    @include breakpoint(medium down){
                        left:0 !important;
                    }
                }
            }

            @include content-box-shadow;
            @include content-border;
            &.services-list-item-content--landingpage {
                @include breakpoint(medium down){
            @include xy-cell(100%);

                }
            }
            @include xy-cell($size: 45.75%, $gutters: rem-calc(60));
            @include flex-align-self(middle);
            background-color: $white;
            padding: rem-calc(42 62 32 50);
            font-weight: $font-weight-light;

            @include breakpoint(large down) {
                @include xy-cell($size: 60%, $gutters: rem-calc(20));
            }

            @include breakpoint(medium down) {
                @include xy-cell;

                padding: rem-calc(21 31 16 25);
            }

            .services-list-item-title {
                font-size: rem-calc(40);
                font-family: $font-family-aktiv-grotesk-thin;
                font-weight: $font-weight-thin;
                color: $blue;
                margin-bottom: rem-calc(17);

                @include breakpoint(medium down) {
                    font-size: rem-calc(34);
                    padding-left: rem-calc(50);
                    line-height: 1;
                }

                .icon-svg {
                    width: rem-calc(54);
                    height: rem-calc(54);
                    vertical-align: text-top;
                    margin-right: rem-calc(3);

                    @include breakpoint (medium down) {
                        width: rem-calc(45);
                        height: rem-calc(45);
                        margin-right: 0;
                        position: absolute;
                        left: rem-calc(25);
                    }

                    .icon-svg-fill {
                        fill: $medium-gray;
                    }
                }

                span {
                    display: inline-block;
                    margin-top: rem-calc(0);
                    vertical-align: text-top;
                }
            }

            .services-list-item-subtitle {
                font-size: rem-calc(19);
                font-weight: $font-weight-medium;
                color: $dark-gray;
                margin-bottom: rem-calc(6);
            }
        }
    }

    .services-list-item--1 {
        .sevices-list-item-content {
            @include flex-align-self(top);
        }
    }

    .services-list-item--odd {
        .services-list-item-photo {
            @include flex-order(2);

            @include breakpoint(medium down) {
                @include flex-order(1);
            }
        }

        .sevices-list-item-content {
            @include flex-order(1);

            @include breakpoint(medium down) {
                @include flex-order(2);
            }
        }
    }
}

.homepage-services-list {
    @include xy-grid;
    @include xy-cell($size: 12, $gutters: 0);
    margin-top: rem-calc(130);
    position: relative;

    @include breakpoint(medium down) {
        margin-top: rem-calc(56);
    }

    .homepage-services-content-container {
        @include xy-cell($size: 47.75%, $gutters: 0);
        @include flex-align($x: justify);
        @include xy-grid($direction: vertical);

        @include breakpoint(medium down) {
            @include xy-cell;
        }

        @include breakpoint(small down) {
            @include xy-cell($gutters: 0);
        }

        .homepage-services-navigation-list {
            text-align: right;
            @include xy-cell($gutters: 0);

            @include breakpoint(medium down) {
                display: none;
            }

            .homepage-services-navigation-list-item {
                &.is-active {
                    a {
                        margin-right: rem-calc(-93);

                        .homepage-services-navigation-list-item-info-arrow {
                            width: rem-calc(15);
                            transition: width 0.25s ease 0;
                        }

                        >.icon-svg {
                            .icon-svg-fill {
                                fill: $blue;
                            }
                        }

                        &:hover {
                            >.icon-svg {
                                opacity: 0.5;
                            }
                        }
                    }
                }

                a {
                    font-size: rem-calc(40);
                    font-family: $font-family-aktiv-grotesk-thin;
                    font-weight: $font-weight-thin;
                    padding: rem-calc(19 36 16 24);
                    line-height: rem-calc(40);
                    display: inline-block;
                    position: relative;
                    z-index: 2;
                    margin-right: 0;
                    background: $light-gray;
                    transition: margin 0.5s ease,
                        background-color 0.25s ease-out,
                        color 0.25s ease-out;

                    @include breakpoint(xlarge only) {
                        font-size: 2.777vw;
                        padding: 1.3194444444444444vw 2.5vw 1.1111111111111112vw 1.6666666666666667vw;
                    }

                    @include breakpoint(large only) {
                        font-size: 2.777vw;
                        padding: 1.3194444444444444vw 2.5vw 1.1111111111111112vw 1.6666666666666667vw;
                    }

                    >.icon-svg {
                        vertical-align: text-top;
                        width: rem-calc(54);
                        height: rem-calc(54);
                        margin-right: rem-calc(4);

                        @include breakpoint(xlarge only) {
                            width: 3.75vw;
                            height: 3.75vw;
                        }

                        @include breakpoint(large only) {
                            width: 3.75vw;
                            height: 3.75vw;
                        }

                        .icon-svg-fill {
                            fill: $medium-gray;
                        }
                    }

                    .homepage-services-navigation-list-info {
                        display: inline-block;
                        margin-top: rem-calc(5);
                        vertical-align: text-top;
                    }

                    .homepage-services-navigation-list-item-info-arrow {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        width: 0;
                        overflow: hidden;
                        background: $blue;
                        transition: width 0.25s ease 0.1s;

                        .icon-svg {
                            width: rem-calc(6);
                            height: rem-calc(15);
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);

                            .icon-svg-fill {
                                fill: $white;
                            }
                        }
                    }
                }
            }
        }
    }

    .homepage-services-images-list-container {
        @include xy-cell($size: 52.25%, $gutters: 0);
        position: relative;

        @include breakpoint(medium down) {
            @include xy-cell;

            margin-top: rem-calc(39);
        }


        .homepage-services-images-list {
            position: relative;
            padding-bottom: 112.7%;

            .homepage-services-images-list-item {
                height:100%;
                width:100%;
                position: absolute;
                top: 0;
                transition: opacity 2000ms ease;

                &[data-aos="homepage-services-image"] {
                    opacity: 0;

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 0;
                        width: rem-calc(155);
                        background: $light-gray;
                        transition: height 1250ms ease 500ms;
                        z-index: 2;

                        @include breakpoint(medium down) {
                            width: rem-calc(83);
                        }
                    }

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: -2px;
                        right: 0;
                        height: 0;
                        width: rem-calc(155);
                        background: $light-gray;
                        transition: height 1250ms ease 750ms;
                        z-index: 2;

                        @include breakpoint(medium down) {
                            width: rem-calc(79);
                        }
                    }

                    .button {
                        opacity: 0;
                    }

                    .homepage-services-images-list-item-image {
                        overflow:hidden;
                        height:100%;
                        width:100%;
                        img {
                            width:100%;
                        }
                        &:after {
                            bottom: 0;
                            right: 0;
                        }
                    }

                    &.aos-animate {
                        &.is-active {
                            opacity: 1;
                        }

                        &:before {
                            height: rem-calc(155);

                            @include breakpoint(medium down) {
                                height: rem-calc(83);
                            }
                        }

                        &:after {
                            height: rem-calc(107);

                            @include breakpoint(medium down) {
                                height: rem-calc(58);
                            }
                        }

                        .button {
                            opacity: 1;
                        }

                        .homepage-services-images-list-item-image {
                            &:before {
                                left: 100%;
                                bottom: 100%;
                            }

                            &:after {
                                right: 100%;
                                top: 100%;
                            }
                        }
                    }
                }

                .button {
                    position: absolute;
                    right: 0;
                    bottom: rem-calc(26);
                    z-index: 3;
                    transition: opacity 750ms ease 1350ms;

                    @include breakpoint(medium down) {
                        display: none;
                    }
                }

                .homepage-services-images-list-item-image {
                    position: relative;

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: $light-gray;
                        z-index: 4;
                        transition: all 1750ms ease 250ms;
                    }

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: $light-gray;
                        z-index: 4;
                        transition: all 2000ms ease 250ms;
                    }
                }
            }
        }
    }

    .homepage-services-mobile-navigation-list {
        display: none;
        margin: rem-calc(10 16 0);
        width: 100%;
        text-align: center;

        .homepage-services-mobile-navigation-list-item {
            display: none;
            width: 100%;

            &.is-active {
                display: block;
            }

            .icon-svg {
                vertical-align: middle;
                width: rem-calc(40);
                height: rem-calc(40);
                margin-right: rem-calc(4);
                margin-bottom: rem-calc(4);

                .icon-svg-fill {
                    fill: $blue;
                }
            }

            .homepage-services-mobile-navigation-list-item-text {
                display: inline-block;
                vertical-align: middle;
                font-family: $font-family-aktiv-grotesk-thin;
                font-weight: $font-weight-thin;
                font-size: rem-calc(26);
            }

            .homepage-services-mobile-navigation-list-item-more {
                text-align: center;
            }
        }

        @include breakpoint(medium down) {
            display: block;
        }
    }
}

.homepage-services-images-list-controls {
    display: none;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 5;

    &[data-aos="homepage-services-image-list-controls"] {
        opacity: 0;
        transition: opacity 500ms ease 1750ms;

        &.aos-animate {
            opacity: 1;
        }
    }

    @include breakpoint(medium down) {
        display: block;
    }

    .homepage-services-images-list-control {
        cursor: pointer;

        &--previous {
            margin-right: rem-calc(4);
        }

        .icon-svg {
            width: rem-calc(34);
            height: rem-calc(34);

            .icon-svg-fill {
                fill: $blue;
            }
        }
    }
}

.services-video {
    width:100%;
    height:100%;
    object-fit:cover;
}