%base-content-border {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        pointer-events: none;
        top: 3px;
        right: 3px;
        bottom: 3px;
        left: 3px;
        border: 1px solid $white;
    }
}

@mixin navigation-content-border() {
    @extend %base-content-border;
    padding: 8px;
}

@mixin footer-content-border() {
    @extend %base-content-border;
    padding: 8px;
}

@mixin button-border() {
    @extend %base-content-border;
    
    &:before {
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
    }
}

@mixin view-more-content-border() {
    @extend %base-content-border;
    padding: rem-calc(30 30 34 36);
    
    &:before {
        border-color: rgba(210,210,217,0.50);
        border-right: none;
        top: 12px;
        left: 12px;
        right: 15px;
        bottom: 12px;
    }
}

@mixin details-button-border() {
    @extend %base-content-border;
    
    &:before {
        top: 3px;
        left: 3px;
        right: 3px;
        bottom: 3px;
    }
}

@mixin image-content-border() {
    @extend %base-content-border;
    
    &:before {
        top: 12px;
        left: 12px;
        right: 12px;
        bottom: 12px;
    }
}

@mixin image-content-border-extend-right() {
    @extend %base-content-border;
    
    &:before {
        top: 12px;
        left: 12px;
        right: 0;
        bottom: 12px;
        border-right: none;
    }
}

@mixin content-border() {
    @extend %base-content-border;
    padding: rem-calc(48 30);

    &:before {
        border-color: rgba(210,210,217,0.50);
        top: 12px;
        left: 12px;
        right: 12px;
        bottom: 12px;
    }
}

@mixin content-border-extend-left() {
    @extend %base-content-border;
    padding: rem-calc(48 30);

    &:before {
        border-color: rgba(210,210,217,0.50);
        top: 12px;
        left: 0;
        right: 12px;
        bottom: 12px;
        border-left: none;
    }
}

@mixin stat-box-content-border() {
    @extend %base-content-border;
    padding: rem-calc(26 30);

    &:before {
        border-color: rgba(210,210,217,0.50);
        top: 12px;
        left: 12px;
        right: 12px;
        bottom: 12px;
    }
}

@mixin content-box-shadow() {
    box-shadow: 0 rem-calc(20) rem-calc(23) 0 rgba(10,35,102,0.07);
}

@mixin content-border() {
    @extend %base-content-border;
    padding: rem-calc(48 30);

    &:before {
        border-color: rgba(210,210,217,0.50);
        top: 12px;
        left: 12px;
        right: 12px;
        bottom: 12px;
    }
}

@mixin faqs-list-item-border() {
    @extend %base-content-border;
    padding: rem-calc(12);

    &:before {
        border-color: rgba(210,210,217,0.50);
        top: 12px;
        left: 12px;
        right: 12px;
        bottom: 12px;
    }
}