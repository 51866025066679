.footer {
    
    @include footer-content-border;
    background: $blue;
    text-align: center;

    .footer-logo {
        margin-top: rem-calc(-44);
        position: relative;
    }
    .footer-social-links {
        margin: rem-calc(28 0 22);
        list-style-type: none;
        .footer-social-link {
            display: inline-block;
            margin-left: rem-calc(20);

            &:first-child {
                margin-left: 0;
            }

            a {
                .icon-svg {
                    .icon-svg-fill {
                        fill: $white;
                    }
                }

                &:hover {
                    .icon-svg {
                        .icon-svg-fill {
                            fill: rgba($white, 0.75);
                        }
                    }
                }
            }
        }
    }

    .footer-form {
        max-width: rem-calc(552);
        margin: auto;

        @include breakpoint(medium down) {
            @include -zf-breakpoint-value(auto, $grid-margin-gutters) {
                padding: rem-calc(0 $-zf-bp-value);
            }
        }

        .footer-form-title {
            color: $white;
            font-size: rem-calc(26);
            font-family: $font-family-aktiv-grotesk-thin;
            font-weight: $font-weight-thin;
            border-bottom: 1px solid $white;
            padding-bottom: rem-calc(6);
            margin-bottom: rem-calc(19);
        }

        .footer-form-row {
            @include xy-grid;
            @include xy-gutters($gutters: rem-calc(16), $negative: true);

            .footer-form-cell {
                @include xy-cell($size: 6, $gutters: rem-calc(16));
                text-align: left;

                @include breakpoint(medium down) {
                    @include xy-cell($gutters: rem-calc(16));
                }

                &--auto {
                    @include xy-cell($size: auto);

                    @include breakpoint(medium down) {
                        @include xy-cell($gutters: rem-calc(16));
                    }

                }
                &--shrink {
                    @include xy-cell($size: shrink);

                    @include breakpoint(medium down) {
                        @include xy-cell($gutters: rem-calc(16));
                        @include -zf-breakpoint-value(auto, $grid-margin-gutters) {
                            margin-top: rem-calc($-zf-bp-value);
                        }
                    }
                }
                &--checkbox {
                    display: table;
                }

                label {
                    color: $white;
                }

                input[type="checkbox"] {
                    margin-right: rem-calc(11);
                }

                .label-checkbox {
                    display: table-cell;
                    font-size: rem-calc(12);
                    font-weight: $font-weight-light;
                    line-height: 1.8333;
                    color: $white;
                }
            }
        }
    }

    .footer-copyright-info {
        color: $white;
        font-size: rem-calc(17);
        font-weight: $font-weight-light;
        margin-top: rem-calc(20);
        margin-bottom: rem-calc(50);
    }
}