.locations-half {
  @include xy-grid;
  width:100%;
    .content-box-location {
        @include xy-cell($size: 4, $gutters: rem-calc(56));
    
        @include breakpoint(medium down) {
            @include xy-cell($size: 8, $gutters: 0);
            @include xy-cell-offset(2, $gutters: 0);
    
            margin-bottom: rem-calc(60);
        }
    
        @include breakpoint(small down) {
            @include xy-cell;
        }
    }
}
.content-row-container-locations {
    @include xy-gutters($gutters: 0, $gutter-type: padding);
}
.content-row-locations {
    background: url('../img/locations-bg.jpg') no-repeat center center;
    background-size: cover;
    padding-top: rem-calc(108);
    padding-bottom: rem-calc(49);
    @include xy-grid;

    @include breakpoint(medium down) {
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .content-box-location {
        @include xy-cell($size: 4, $gutters: rem-calc(56));

        @include breakpoint(medium down) {
            @include xy-cell($size: 8, $gutters: 0);
            @include xy-cell-offset(2, $gutters: 0);

            margin-bottom: rem-calc(60);
        }

        @include breakpoint(small down) {
            @include xy-cell;
        }
    }
}

.content-box-location {
    @include content-box-shadow;
    background-color: $white;
    padding: 0;
    @include flex;
    @include flex-direction(column);


    .content-box-location-image {
        @include xy-cell($gutters: 0);
        @include image-content-border;

        &:before {
            border-bottom: none;
            bottom: 0;
        }

        img {
            width: 100%;
        }
    }
    .content-box-location-content-container {
        @include xy-grid;
        padding: rem-calc(30);

        @include breakpoint(medium down) {
            padding-top: 0;
        }

        .content-box-location-content-column-1 {
                @include xy-cell;
                @include flex-order(2);

            .content-box-location-map {
                padding-bottom: 49.2163%;
                border: 1px solid $light-gray;
                border-bottom: none;
            }

            .button {
                width: 100%;
            }
        }
        .content-box-location-content-column-2 {

                @include xy-cell;
                @include flex-order(1);
                text-align: center;
                margin-bottom: rem-calc(20);

            .content-box-location-content-title {
                font-size: rem-calc(30);
                font-weight: $font-weight-light;
                border-bottom: 1px solid #D2D2D9;
                color: $blue;
                margin-top: rem-calc(11);
                padding-bottom: rem-calc(1);
                margin-bottom: rem-calc(14);
            }
            .content-box-location-content-info {
                font-size: rem-calc(17);
                font-weight: $font-weight-regular;
            }
        }
    }
}