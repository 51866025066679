.button {
    @include button-border;
    font-weight: $font-weight-regular;
    margin-bottom: 0;

    @include breakpoint(small down) {
        margin-bottom: rem-calc(10);
    }

    &:after {
        content: '';
        background: url("../img/button-arrow.svg") no-repeat center center;
        display: block;
        width: rem-calc(7);
        height: rem-calc(41);
        position: absolute;
        top: 3px;
        right: 2px;
    }

    & + .button {
        margin-left: rem-calc(15);

        @include breakpoint(small down) {
            margin-left: 0;
        }
    }
}

.form-button-wrapper {
    @include button-border;
    font-weight: $font-weight-regular;
    margin-bottom: 0;
    display: inline-block;

    &:before {
        z-index: 2;
        top: 3px;
        right: 3px;
        bottom: 3px;
        left: 3px;
    }

    &:after {
        content: '';
        background: url("../img/button-arrow.svg") no-repeat center center;
        display: block;
        width: rem-calc(7);
        height: rem-calc(41);
        position: absolute;
        top: 4px;
        right: 3px;
    }

    .form-button {

    }
}