.page-header {
    position: relative;
    margin-bottom: rem-calc(-50);

    @include breakpoint(medium down) {
        margin-bottom: 0;
    }

    .page-header-image {
        width: 100%;
    }
    .page-header-content-container {
        @include xy-grid-container;
        @include xy-grid;
        
        .page-header-content {
            @include xy-cell;
            .page-header-content-title {
                font-weight: $font-weight-regular;
                font-size: rem-calc(128);
                color: $white;
                text-shadow: 0 0 45px rgba($blue,0.24);
                position: absolute;
                bottom: rem-calc(50);
                line-height: rem-calc(97);
                margin-bottom: 0;
                margin-left: rem-calc(40);
                z-index: 1;

                @include breakpoint(medium down) {
                    font-size: rem-calc(60);
                    line-height: rem-calc(45);
                    bottom: 0;
                    margin-left: 0;
                }
            }
        }
    }
}