.content-row-team-navigation {
    @include xy-grid-container;
    @include xy-grid;

    @include breakpoint(medium down) {
        display: none;
    }

    .content-box-team-navigation-box {
        @include xy-cell(12);
        @include xy-grid;
        background-color: $white;
        @include content-box-shadow;
        @include content-border;
        padding: rem-calc(28 38 32);
        @include flex-align(center, middle);

        .content-box-team-navigation-item {
            @include xy-cell(shrink);
            @include flex;
            @include flex-align(center, middle);
            padding-left: rem-calc(26);

            &:first-child {
                padding-left: 0;
            }

            a {
                font-size: rem-calc(26);
                font-family: $font-family-aktiv-grotesk-thin;
                font-weight: $font-weight-thin;

                .icon-svg {
                    vertical-align: text-top;
                    width: rem-calc(49);
                    height: rem-calc(49);
                    margin-right: rem-calc(4);

                    .icon-svg-fill {
                        fill: $medium-gray;
                    }
                }

                span {
                    display: inline-block;
                    margin-top: rem-calc(6);
                    vertical-align: text-top;
                }
            }
        }
    }
}

.team-list-section {
    @include xy-cell($gutters: rem-calc(50));
    @include content-border;
    @include content-box-shadow;
    background: $white;
    margin-top: rem-calc(49);
    padding: rem-calc(26 30 16);
    margin-bottom: rem-calc(30);

    .team-list-section-title {
        font-size: rem-calc(32);
        font-family: $font-family-aktiv-grotesk-thin;
        font-weight: $font-weight-thin;
        color: $blue;
        float: left;
    }
    
    .link-to-top {
        top: 50%;
        transform: translateY(-50%);
        width: rem-calc(34);
        height: rem-calc(34);

        .icon-svg {
            width: rem-calc(34);
            height: rem-calc(34);
        }
    }
}

.team-list {
    @include xy-grid;
    @include xy-gutters($gutters: rem-calc(30), $negative: true);

    @include breakpoint(medium down) {
        @include xy-gutters($gutters: 15);
    }

    @include breakpoint(small down) {
        @include xy-gutters;
    }

    .team-list-group {
        @include xy-cell($gutters: 0);
        .team-list-group-title-wrapper {
            
            .team-list-group-title-container {
                @include xy-cell($gutters: rem-calc(50));
                @include content-border;
                @include content-box-shadow;
                background: $white;
                padding: rem-calc(26 30);

                @include breakpoint(medium down) {
                    @include xy-cell($gutters: 0);
                }

                .team-list-group-title {
                    margin-bottom: 0;
                    font-size: rem-calc(32);
                    font-family: $font-family-aktiv-grotesk-thin;
                    font-weight: $font-weight-thin;
                    color: $blue;
                    
                }

                .link-to-top {
                    top: 50%;
                    transform: translateY(-50%);
                    width: rem-calc(34);
                    height: rem-calc(34);
            
                    .icon-svg {
                        width: rem-calc(34);
                        height: rem-calc(34);
                    }
                }
            }
        }

        .team-list-group-list {
            margin-top: rem-calc(30);
            @include xy-grid;
            
            .team-list-item {
                @include xy-cell($size: 4, $gutters: rem-calc(50));
                @include content-box-shadow;
                @include xy-grid($direction: vertical);
                margin-bottom: rem-calc(83);

                @include breakpoint(medium down) {
                    @include xy-cell($size: 6);
                }

                @include breakpoint(small  down) {
                    @include xy-cell($gutters: 0);
                }                

                .team-list-item-photo {
                    @include image-content-border;
                    @include xy-cell($size: shrink, $gutters: 0);
                    max-width: 100%;
                }
                .team-list-item-info {
                    @include view-more-content-border;
                    @include xy-cell($size: full, $gutters: 0);
                    flex-grow: 1;
                    background: $white;
        
                    .team-list-item-info-name {
                        display: block;
                        font-size: rem-calc(32);
                        font-family: $font-family-aktiv-grotesk-thin;
                        font-weight: $font-weight-thin;
                        color: $blue;
                        border-bottom: 1px solid #D2D2D9;
                        padding-bottom: rem-calc(1);
                        margin-bottom: rem-calc(6);
                    }
                    .team-list-item-info-title {
                        display: block;
                        color: $dark-gray;
                        font-size: rem-calc(21);
                        font-weight: $font-weight-light;
                    }
                    .team-list-item-info-arrow {
                        position: absolute;
                        background: $blue;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        width: rem-calc(15);

                        .icon-svg {
                            position: absolute;
                            width: rem-calc(6);
                            height: rem-calc(15);
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            
                            .icon-svg-fill {
                                fill: $white;
                            }
                        }
                    }
                }
            }
        }
    }
}

.team-member-bio-container {
    @include xy-grid;

    .team-member-bio-content {
        @include xy-cell($size: auto);

        @include breakpoint(medium down) {
            @include xy-cell($gutters: 0);
        }
        .team-member-bio-name {
            font-size: rem-calc(32);
            font-family: $font-family-aktiv-grotesk-thin;
            font-weight: $font-weight-thin;
            color: $blue;
            border-bottom: 1px solid #D2D2D9;
            padding-bottom: rem-calc(1);
            margin-bottom: rem-calc(6);
        }
        .team-member-bio-title {
            color: $dark-gray;
            font-size: rem-calc(21);
            font-weight: $font-weight-light;
        }
        .team-member-bio-bio {
            margin-top: rem-calc(20);
        }
    }

    .team-member-bio-photo-container {
        @include xy-cell($size: shrink);

        @include breakpoint(medium down) {
            @include xy-cell($gutters: 0);
        }

        .team-member-bio-photo {
            @include image-content-border;
        }
    }
}